import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, Validators } from '@angular/forms';
import { SharedGlobalService } from '../../../@core/services/shared.global.service';


@Component({
  selector: 'ngx-add-user',
  templateUrl: './add-user.component.html',
  styleUrls: ['./add-user.component.scss']
})
export class AddUserComponent implements OnInit {

  loading = true;
  public form: any;
  data;

  constructor(
    private activeModal: NgbActiveModal,
    private formBuilder: FormBuilder,
    private sgs: SharedGlobalService
  ) {
    this.createForm();
  }

  createForm() {
    this.form = this.formBuilder.group({
      role:         ['', [Validators.required]],
      username:     ['', [Validators.required]],
      password:     ['', [Validators.required]],
      lname:        ['', [Validators.required]],
      fname:        ['', [Validators.required]],
      mname:        ['', [Validators.required]],
    })
  }

  ngOnInit() {
  }



  addUser(data){
    this.sgs.request('post', 'user/createUser', data.value, async (response) => {
      if(response.success){
        this.sgs.Toaster('success', 'Success', 'New user has been added.');
      }
    }, { cache:true, describe: "ERROR: Adding user" })
  }

  closeModal() {
    this.activeModal.close();
  }

}
