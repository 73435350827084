import { Component, OnInit } from '@angular/core';
import { SharedGlobalService } from '../../../../@core/services/shared.global.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute,Router } from '@angular/router';
@Component({
  selector: 'ngx-customer-details',
  templateUrl: './customer-details.component.html',
  styleUrls: ['./customer-details.component.scss']
})
export class CustomerDetailsComponent implements OnInit {
  conx: any = this.sgs.connection;
  bsValue = new Date();
  filterQuery = '';
  sortBy = 'id';
  sortOrder = 'asc';
  selectQueryString = 'Last Name';
  selectQuery = 'lname';
  data;
  loading = true;
  date = new Date();
  todate;
  private socketInstance;
  id:any;
  logourl:any = '';
  profileurl:any='';
  haschanged:boolean;
  constructor(
    public sgs: SharedGlobalService,
    private ngbModal: NgbModal,
    private fb: FormBuilder,
    public route: ActivatedRoute,
    public router: Router,
  ) {
   }

  ngOnInit() {
    this.id = this.route.snapshot.params['id'];
    this.getClient();
  }

   getClient(){
      this.sgs.request('get', 'customer/getCustomerWithID', {id:parseInt(this.id)}, async (response) => {
      if( response.success ){
          this.data = response.data[0];
          this.loading = false;
      }
    },{cache:true, describe: "Error getting clients!" });
  }

  uploadLogo(){

  }

  uploadProfile(){

  }

  elEventListenerActive: boolean;

  openFile(ev, id){
    var url:any;
    let file,
        el = document.getElementById(id);
        el.click();


        let handler = (fc) => {
          try{
        let fileList: any;
        let fd = new FormData();
            if(fc.target['files'][0]['name'] !== undefined){
              fileList = fc.target;
              let file: File = fileList.files[0];
              url = file.name;
              // ev.target.innerHTML = 'Change';
              
                fd.append('degree_attachment', file, file.name);

                this.sgs.request('post', 'xfile/profileImage', fd, response => {
                  if(response.success){
                    this.elEventListenerActive = false;
                    url = response.data.name;
               
                      this.data.profileurl = url;
                      this.haschanged = true;
                    
                    el.removeEventListener('change', handler);
                    // this.sgs.Modal({
                    //   header: `Image successfully uploaded`,
                    //   content: `
                    //     <b>Image name:</b> ${file.name} <br />
                    //     <b>Renamed by server to:</b> ${response.data.name} <br /><br />
                    //     <img width="100%" src="${this.sgs.connection}/images/${response.data.name}">
                    //   `,
                    //   buttonName: 'close'
                    // }, { size: 'md' });
                  }else{
                    url = '';
                    el.removeEventListener('change', handler);
                  }
                });


            }else{
              url = '';
              ev.target.innerHTML = 'Browse';
              this.elEventListenerActive = false;
              el.removeEventListener('change', handler);
            }
          }catch(e){
            url = '';
            ev.target.innerHTML = 'Browse';
            this.elEventListenerActive = false;
            el.removeEventListener('change', handler);
          }
        }
        
        if( !this.elEventListenerActive ){
          el.addEventListener('change', handler);
          this.elEventListenerActive = true;
        }
  }


  submit(){
    this.sgs.request('post', 'customer/updateCustomer', this.data, async (response) => {
      if(response.success){
        this.sgs.Toaster('success', 'Success', 'Client has been updated successfully.');
       this.haschanged = false;
      }
    }, { cache:true, describe: "ERROR: Client update" })
  }


  // addUser(){
  //   const activeModal = this.ngbModal.open(AddUserComponent, { size: 'sm', container: 'nb-layout', windowClass: 'min_height' });
  // }

  // updateUser(id){
  //   const activeModal = this.ngbModal.open(UpdateUserComponent, { size: 'sm', container: 'nb-layout', windowClass: 'min_height' });
  //   activeModal.componentInstance.uid = id;
  // }



  

}
