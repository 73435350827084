import { Injectable,  EventEmitter, Output } from '@angular/core';
import 'rxjs/add/operator/map';
import { AuthService } from './auth.service';
import { SharedGlobalService } from './shared.global.service';

@Injectable()


export class UserGlobalService {
    public $userData:any; 
    public webSettings:any;
    @Output() public loadData = new EventEmitter();
    socketInstance;
    constructor(
        private auth:AuthService,
        private sgs:SharedGlobalService,
    ){
        if(this.auth.getTokenData() && this.auth.getTokenData().role){
            this.socketInstance = sgs.ResponseSocket(this.auth.getTokenData().role+'s').subscribe( emitted => {
                if(emitted ){
                    this.getUserCollectionData();
                }
            });
        }
    }
    
    public getUserCollectionData(){
        var userData = this.auth.getTokenData();
        var role= '';
        var api='';
        var result:any;
        if(userData && userData.role){
            switch(userData.role){
                case 'admin':
                    role = 'admin';
                    api = 'getAdminWithUID';
                break;

                case 'client':
                    role = 'client';
                    api = 'getClientWithUID';
                break;

                case 'clientsub':
                    role = 'clientsub';
                    api = 'getClientSubWithUID';
                break;

                case 'consultant':
                    role = 'consultant';
                    api = 'getConsultantWithUID';
                break;
                
                default:
                    role = 'unidentified';
                break;
            }
        }
    
        if(userData && role != 'unidentified'){
            return new Promise(resolve => {
                this.sgs.request('get', role+'/'+api, {id:parseInt(userData.id)}, async (response) => {
                    
                    if( response.success ){
                        switch(role){
                            case 'admin':
                                result = response.data;
                            break;

                            case 'client':
                                result = response.data;
                                this.webSettings = response.data.webSettings? response.data.webSettings:{marketingMyBusiness:{}, appContent:{}};
                            break;

                            case 'clientsub':
                                result = response.data;
                                this.webSettings = response.data.webSettings? response.data.webSettings:{marketingMyBusiness:{}, appContent:{}};

                            break;
                            
                            case 'consultant':
                                result = response.data[0];
                                this.webSettings = response.data[0].company.webSettings? response.data[0].company.webSettings:{marketingMyBusiness:{}, appContent:{}};;
                            break;
                            
                            default:
                                role = 'unidentified';
                            break;
                        }
                        this.$userData = result;
                        //  this.loadData.emit(result);
                        return resolve(this.$userData);
                    }else{
                        result = null;
                        this.auth.logout();
                    }
                },{cache:true, describe: "Error getting clients!" });
            })
        }else{
            // this.auth.logout();
        }
    };

    /**
     * 
     * @param ID - User ID in users collection
     * @param role - User role to define which collection it will get.
     */
    public getUserPpsCredentials( ID ){
        return new Promise(resolve => {
            this.sgs.request('get', 'consultant/getConsultantPpsCredentials', { id: parseInt(ID) }, async (response) => {
                if( response.success ){
                    return resolve( { success: true, data: response.data } );
                }else{
                    return resolve( { success: false } );
                }
            })
        })
    }
}
