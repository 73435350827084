import * as _ from 'lodash';
import { Injectable, Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ConnectionService } from '../services/connection.service';

@Pipe({
  name: 'dataFilter'
})
@Injectable()
export class dataFilterPipe implements PipeTransform {
transform(array: any[], query: string, selector: string): any {
    if (query) {
         if(selector == 'tags'){
            array = _.filter(array, (data, index) => {

                return  _.some( data['tags'], (a) => {
                  return a['value'].includes(query);
                });
              });
            return array;

         }else if(selector == 'points'){
            return _.filter(array, (data) => {
                if((data[selector] || '').toString().indexOf(query) > -1){
                    return array;
                }
              });

         }else{
            return _.filter(array, row => {
                if(Object.prototype.toString.call(query) === '[object Date]'){
                    let qd = new Date(query);
                    let qday = qd.getDate();
                    let qmonth = qd.getMonth();
                    let qyear = qd.getFullYear();

                    let rd = new Date(row[selector]);
                    let rday = rd.getDate();
                    let rmonth = rd.getMonth();
                    let ryear = rd.getFullYear();

                    let queryDate = [qday, qmonth, qyear].join('');
                    let rowDate = [rday, rmonth, ryear].join('');

                    if(queryDate === rowDate){
                        return array;
                    }

                }else if(typeof row[selector] == 'string' && (row[selector] || '').toString().toLowerCase().indexOf(query.toLowerCase()) > -1){
                    return array;
                }
            });
         }

    }
        return array;

    }
}

@Pipe({
  name: 'searchFilter'
})
@Injectable()
export class searchDataFilterPipe implements PipeTransform {
transform(array: any[], query: string, selector: string): any {
      let temp = array;
      if(query){
            return _.filter(array, row => {
              if(typeof row[selector] == 'string' && (row[selector] || '').toString().toLowerCase().indexOf(query) > -1){
                 return array;
             }
            })
      }else{
          return array;
      }
    }
}

@Pipe({
    name: 'tagsDataFilter'
  })
  @Injectable()
  export class tagsDataFilterPipe implements PipeTransform {
  transform(array: any[], query: string, selector: string): any {
        let temp = array;
        if(query){
            temp = _.filter(temp, (data, index) => {
                return  _.some( data['tags'], (a) => {
                  return a['value'].includes(query);
                });
              });
            return temp;
        }else{
            return temp;
        }



      }
  }

  @Pipe({ name: 'imageGallery' })
export class ImageGallery implements PipeTransform {
  constructor(private cs: ConnectionService, private sanitizer: DomSanitizer) {}
  transform(src:String, path:String, type:String, ) {
      var fullPath = `${this.cs.connection}/${path}/${src}`;
    switch( type ){
        case 'html': return `${this.cs.connection}/${path}/${src}`
        case 'css': return this.sanitizer.bypassSecurityTrustStyle(`url('${fullPath}')`);
        case 'local-html': return `${src}`
        case 'local-css': return `url('${src}')`;
    }
  }
}

  @Pipe({
    name: 'multiFilter'
})

export class multiFilterPipe implements PipeTransform {
    transform(array: any[], query: string, mf: any, def:string): any {
        let temp = [];
        query = query.toString().toLowerCase();

        if( mf.length ){
            if(query){
                mf.forEach( prop => {
                    array.forEach( result => {
                        if( prop.name.includes('.') ){
                            //second level searching #support
                            let x = prop.name.split('.')[0];
                            let y = prop.name.split('.')[1];
                            if( (result[x][y] || '').toString().toLowerCase().includes(query) )
                                temp.push(result)
                        }else{
                            if( (result[prop.name] || '').toString().toLowerCase().includes(query) )
                                temp.push(result)
                        }
                    })
                });
                return temp;
            }else{
                return array;
            }
        }else{
            if(query){
                if( def.includes('.') ){
                    //second level searching #support
                    let x = def.split('.')[0];
                    let y = def.split('.')[1];
                    return array.filter( e => (e[x][y] || '').toString().toLowerCase().includes(query))
                }
                return array.filter( e => (e[def] || '').toString().toLowerCase().includes(query))
            }
            return array;
        }
    }
}

  //Sample Usage => (1234.9876 | numberWithCommas : 2)
@Pipe({
  name: 'numberWithCommas',
})

export class NumberWithCommas implements PipeTransform {
  transform(num, places) {
      try {
          if (num > -1) {
              let decimalStr;
              if (String(num).split('.').length == 2 ) {
                  if ( String(num).split('.')[1].length == 1) {
                      decimalStr = num.toString() + '0';
                  }else {
                      decimalStr = num.toString().split('.')[0] + num.toString().substr(num.toString().indexOf('.'), places + 1);
                  }
              } else {
                  decimalStr = num.toString() + '.00';
              }
              const parts = decimalStr.split('.');
              parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
              return parts.join('.');
          }
      }catch (e) {
          console.info('Math Error: ', e.message);
      }
  }
}

@Pipe({
    name: 'reversedate',
})

export class ReverseDate implements PipeTransform {
    transform(date: any): any {
        let newd = new Date(date);
        return new Date(newd.setHours(newd.getHours() - 8));
    }
}

@Pipe({
  name: 'countByValue',
})
export class CountByValue implements PipeTransform {
  transform(items: any[], str: string, value: any, qty?, not?) {
      let count = 0;
      for (let i = 0; i < items.length; i++) {
          if (items[i][str] == value || str === 'all') {
              if (qty) {
                  count += items[i][qty];
              } else {
                  //count++;
                  if (not) {
                      if (items[i][not.prop] !== not.value) {
                          count++;
                      }
                  } else {
                      count++;
                  }
              }
          }
      }
      return count;
  }
}
