import { NbMenuItem } from '@nebular/theme';

export const MENU_ITEMS: NbMenuItem[] = [
  {
    title: 'Dashboard',
    icon: 'nb-home',
    link: '/admin/dashboard',
    home: true,
    // children: [
    //   {
    //     title: 'Menu 1',
    //     link: '/admin/dashboard',
    //   },
    //   {
    //     title: 'Menu 2',
    //     link: '/admin/dashboard',
    //   }
    // ]
  },
  {
    title: 'Users',
    icon: 'fas fa-users-cog',
    link: '/admin/users/all-users',
    home: true,
  },
  {
    title: 'Clients',
    icon: 'fas fa-building',
    link: '/admin/clients/all-clients',
    home: true,
  },
  {
    title: 'Logs',
    icon: 'fas fa-file-alt',
    link: '/admin/clients/all-clients',
    home: true,
  },
];
