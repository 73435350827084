import { Component, OnInit } from '@angular/core';
import { SharedGlobalService } from '../../../@core/services/shared.global.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';


@Component({
  selector: 'ngx-clients-list',
  templateUrl: './clients-list.component.html',
  styleUrls: ['./clients-list.component.scss']
})
export class ClientsListComponent implements OnInit {

  bsValue = new Date();
  filterQuery = '';
  sortBy = 'id';
  sortOrder = 'asc';
  selectQueryString = 'Last Name';
  selectQuery = 'lname';
  data;
  loading = true;
  date = new Date();
  todate;
  rowsOnPage = 10;
  private socketInstance;

  constructor(
    public sgs: SharedGlobalService,
    private ngbModal: NgbModal
  ) {

    //amo ni ang socket nga naga listen sg emit ka server
    //at first load indi xa mag run --- waiting lng ni xa emit.
    this.socketInstance = sgs.ResponseSocket('clients').subscribe( emitted => {
      //on emit; false
      this.getAllUsers(false);
    });
   }

  ngOnInit() {
    //nagsala ko sa client kg users cliebts = users?
    // rename ko pa na.. ahhh
//at first load amo ni ang ma run
//copy dad
    this.getAllUsers();
  }

  ngOnDestroy(){
    this.socketInstance.unsubscribe();
  }

  //at first run, ma load ang loader; loading = true
  getAllUsers(socketLoader = true){
    this.sgs.request('get', 'client/getAllClients', null, async (response) => {
      if( response.success ){
          this.data = response.data;
       //this.loading = false; //ky custom loading gali hehehe. trY ko laNG
      }
    },{ socketLoader:socketLoader, cache:true, describe: "Error getting clients!" });
  }


  selectFilter(name, value){
    this.selectQuery = name;
    this.selectQueryString = value;
  }


  // addUser(){
  //   const activeModal = this.ngbModal.open(AddUserComponent, { size: 'sm', container: 'nb-layout', windowClass: 'min_height' });
  // }

  // updateUser(id){
  //   const activeModal = this.ngbModal.open(UpdateUserComponent, { size: 'sm', container: 'nb-layout', windowClass: 'min_height' });
  //   activeModal.componentInstance.uid = id;
  // }

  changeStatus(id,companyname,status){
   var stat =  status? 'Activate Client':'Deactivate Client';
   var newStatus = !status;
   var message = status? 'Client has been activated':'Client has been deactivated';
   var content = status? 'Are you sure to activate <b> '+companyname+' </b>?': 'Are you sure to deactivate <b> '+companyname+' </b>?';
   
    this.sgs.Modal({
      header: stat,
      content: content,
      type: 'confirmation',
    }, { size: 'sm' })
    .confirm.subscribe( response => {
      if( response ){
        this.sgs.request('post', 'client/deactivateClient', {id:id, status:newStatus}, response => {
          if(response){
            this.sgs.Toaster('success', 'Success', message);
          }
        })
      }
    });
  }
  deleteClient(id,companyname,status){
    this.sgs.Modal({
      header: 'Delete Client',
      content: 'Are you sure you want to delete <b> '+companyname+' </b>?',
      type: 'confirmation',
    }, { size: 'sm' })
    .confirm.subscribe( response => {
      if( response ){
        this.sgs.request('post', 'client/deleteClient', {id:id, status:2}, response => {
          if(response){
            this.sgs.Toaster('success', 'Success', 'Client has been deleted successfully');
          }
        })
      }
    });
  }
  

  disableAccount(id){
    var answer = confirm("Deactivate user account?")
    if (answer) {
      this.sgs.request('post', 'user/deactivateUser', {id: id}, async (response) => {
        this.sgs.Toaster('warning', 'Warning', 'The user has been deactivated!');
      })
    }
  }

  enableAccount(id){
    var answer = confirm("Activate user account?")
    if (answer) {
      this.sgs.request('post', 'user/enableUser', {id: id}, async (response) => {
        this.sgs.Toaster('success', 'Success', 'The user has been activated!');
      })
    }
  }

}
