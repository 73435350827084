import { Component, OnInit } from '@angular/core';
import { SharedGlobalService } from '../../../../@core/services/shared.global.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AuthService } from '../../../../@core/services/auth.service';
import { UserGlobalService } from '../../../../@core/services/user.global.service';


@Component({
  selector: 'ngx-customer-list',
  templateUrl: './customer-list.component.html',
  styleUrls: ['./customer-list.component.scss']
})
export class CustomerListComponent implements OnInit {

  bsValue = new Date();
  filterQuery = '';
  sortBy = 'id';
  sortOrder = 'asc';
  selectQueryString = 'Last Name';
  selectQuery = 'lname';
  data;
  loading = true;
  date = new Date();
  todate;
  private socketInstance;
  userData:any;
  isResent:boolean;
  rowsOnPage = 10;
  constructor(
    public sgs: SharedGlobalService,
    private ngbModal: NgbModal,
    public auth:AuthService,
    private ugs: UserGlobalService
  ) {
    this.socketInstance = sgs.ResponseSocket('customers').subscribe( emitted => {
      this.getAllUsers(false);
    });
   }

  ngOnInit() {
    this.getUser();
  }

  async getUser(){
    this.userData = this.ugs.$userData? this.ugs.$userData:await this.ugs.getUserCollectionData();
    this.getAllUsers();
  }

  ngOnDestroy(){
    this.socketInstance.unsubscribe();
  }

  getAllUsers(socketLoader = true){
    this.sgs.request('get', 'customer/getConsultantAllCustomers', {id:this.userData.uid}, async (response) => {
      if( response.success ){
          this.data = response.data;
      }
    },{ socketLoader:socketLoader, cache:true, describe: "Error getting customers!" });
  }

  selectFilter(name, value){
    this.selectQuery = name;
    this.selectQueryString = value;
  }

  changeStatus(data){
    var stat =  data.isdisabled? 'Activate Customer':'Deactivate Customer';
    var newStatus = !data.isdisabled;
    var message = data.isdisabled? 'Customer has been activated':'Customer has been deactivated';
    var content = data.isdisabled? 'Are you sure to activate <b> '+data.fname+' '+data.lname+' </b>?':'Are you sure to deactivate <b> '+data.fname+' '+data.lname+ '</b>?';
   
    this.sgs.Modal({
      header: stat,
      content: content,
      type: 'confirmation',
    }, { size: 'sm' })
    .confirm.subscribe( response => {
      if( response ){
        this.sgs.request('post', 'customer/deactivateCustomer', {id:data.id, status:newStatus}, response => {
          if(response){
            this.sgs.Toaster('success', 'Success', message);
          }
        })
      }
    });
  }

  deleteCustomer(data){
    this.sgs.Modal({
      header: 'Delete Customer',
      content: 'Are you sure you want to delete <b> '+data.fname+' '+data.lname+' </b>?',
      type: 'confirmation',
    }, { size: 'sm' })
    .confirm.subscribe( response => {
      if( response ){
        this.sgs.request('post', 'customer/deleteCustomer', {id:data.id, status:2}, response => {
          if(response){
            this.sgs.Toaster('success', 'Success', 'Customer has been deleted successfully');
          }
        })
      }
    });
  }
  

  disableAccount(id){
    var answer = confirm("Deactivate user account?")
    if (answer) {
      this.sgs.request('post', 'user/deactivateUser', {id: id}, async (response) => {
        this.sgs.Toaster('warning', 'Warning', 'The user has been deactivated!');
      })
    }
  }

  enableAccount(id){
    var answer = confirm("Activate user account?")
    if (answer) {
      this.sgs.request('post', 'user/enableUser', {id: id}, async (response) => {
        this.sgs.Toaster('success', 'Success', 'The user has been activated!');
      })
    }
  }

  resend(user){
    const data = {
      toEmail: user.email,
      toName: user.fname + " " + user.lname,
      subject: this.userData.companyname + ' App - New Consultant Signup',
      content: '<html><img  style=" width:20%; border:4px solid #d76d9d;" src="'+this.userData.company.webportal+'/images/'+this.userData.company.logourl+'"><br>Welcome ' + user.fname + ' to the ' + this.userData.company.companyname + ' App! <br><p>Please follow the 4-easy steps to confirm your account. </p> <p>Step 1: Click on the link provided: <a href="' + this.userData.company.webportal + '/#/verification/customer/' + this.userData.company.companyid + '/'+this.userData.uid+ '/'+user.id+'">Confirm My Account</a></p>  <p>Step 2: Create and Memorize your password to activate your account</p> <p>Step 3: Click on the download link and install the app</p> <p>Step 4. Login to your app using your email address that was displayed in "Step 2" and the password you created, and expand your network!</p><br><br>' + this.userData['signature'] + '</html>'
    }
    // console.log(data);
    this.sgs.request('post','communication/sendEmail', data, async response => {
      if(response.success){
        user.isResent = true;
        this.sgs.Toaster('success', 'Invitation Re-sent', 'The invitation was re-sent successfully.');
      }else{
        this.sgs.Toaster('warning', 'Email not Sent', 'The invitation was not re-sent successfully. Please try to resend it again.');
      }
    })
  }

}
