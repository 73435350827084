import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'ngx-spinner-modal',
  templateUrl: './spinner-modal.component.html',
  styleUrls: ['./spinner-modal.component.scss']
})
export class SpinnerModalComponent implements OnInit {
  destroyModalObservable;
  constructor(
    private activeModal: NgbActiveModal,
  ) { }

  ngOnInit() {
  this.destroyModalObservable.subscribe(res => {
    this.closeModal();
  })
  }
  closeModal() {
    this.activeModal.close();
  }

 
}
