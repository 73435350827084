import { Injectable } from '@angular/core';
import { Router, CanActivate } from '@angular/router';
import { AuthService } from '../@core/services/auth.service';
import { UserGlobalService } from '../@core/services/user.global.service';

@Injectable()
export class AuthGuard implements CanActivate {
    constructor(
        private authService: AuthService, 
        private router: Router,
        public ugs:UserGlobalService
        )
    {
      
    }

    async canActivate() {
        try{
            if (this.authService.hasToken()) {
                // var user = this.ugs.$userData;
                // if(user['role'] == 'consultant'){
                //     if(user['ppspassword'] && user['ppsid']){
                //         return true;
                //     }else{
                //         this.authService.logout();
                //         return false;
                //     }
                // }else{
                //     return true;
                // }
                return true;
            }
            else {
                this.authService.logout();
                return false;
            }
        }catch(err){
            console.log(err);
        }

    }

 

}
