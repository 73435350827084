import { Component, OnInit } from '@angular/core';
import { SharedGlobalService } from '../../../@core/services/shared.global.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AddUserComponent } from '../add-user/add-user.component';
import { UpdateUserComponent } from '../update-user/update-user.component';

@Component({
  selector: 'ngx-users-list',
  templateUrl: './users-list.component.html',
  styleUrls: ['./users-list.component.scss']
})
export class UsersListComponent implements OnInit {

  bsValue = new Date();
  filterQuery = '';
  sortBy = 'id';
  sortOrder = 'asc';
  selectQueryString = 'Last Name';
  selectQuery = 'lname';
  data;
  loading = true;
  date = new Date();
  todate;
  private socketInstance;
  rowsOnPage = 10;
  constructor(
    public sgs: SharedGlobalService,
    private ngbModal: NgbModal
  ) {
    this.socketInstance = sgs.ResponseSocket('users').subscribe( emitted => {
      this.getAllUsers();
    });
   }

  ngOnInit() {
    this.getAllUsers();
  }

  ngOnDestroy(){
    this.socketInstance.unsubscribe();
  }

  getAllUsers(){
    this.sgs.request('get', 'user/getAllUsers', null, async (response) => {
      if( response.success ){
       this.data = response.data;
       this.loading = false;
      }
    },{cache:true, describe: "Error getting users!" });
  }


  selectFilter(name, value){
    this.selectQuery = name;
    this.selectQueryString = value;
  }


  addUser(){
    const activeModal = this.ngbModal.open(AddUserComponent, { size: 'sm', container: 'nb-layout', windowClass: 'min_height' });
  }

  updateUser(_id, id){
    const activeModal = this.ngbModal.open(UpdateUserComponent, { size: 'sm', container: 'nb-layout', windowClass: 'min_height' });
    activeModal.componentInstance.uid = _id;
    activeModal.componentInstance.docId = id;
  }

  disableAccount(id){
    var answer = confirm("Deactivate user account?")
    if (answer) {
      this.sgs.request('post', 'user/deactivateUser', {id: id}, async (response) => {
        this.sgs.Toaster('warning', 'Warning', 'The user has been deactivated!');
      })
    }
  }

  enableAccount(id){
    var answer = confirm("Activate user account?")
    if (answer) {
      this.sgs.request('post', 'user/enableUser', {id: id}, async (response) => {
        this.sgs.Toaster('success', 'Success', 'The user has been activated!');
      })
    }
  }

}
