import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'spinner',
  styleUrls: ['./spinner.component.scss'],
  templateUrl: './spinner.component.html',
})

export class SpinnerComponent implements OnInit {
  @Input() hide:boolean;
  @Input() isEmpty:boolean;
  @Input() message:string;
  @Input() emptyMessage:boolean;
  ngOnInit(){
  }
}
