import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
@Pipe({
  name: 'youtube',
})
export class YoutubePipe implements PipeTransform {
  constructor(private dom: DomSanitizer) {
  }
  transform(value: string, ...args) {
    if(value != null){
      var uniqueId = value.split("=")[1];
        return uniqueId ;
    }
  }
}