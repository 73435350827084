import { ExtraOptions, RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';

import { AuthGuard } from './guards/auth.guard';
import { NotAuthGuard } from './guards/notAuth.guard';

const routes: Routes = [
  { path: 'login',                                                loadChildren: 'app/login/login.module#LoginModule' },
  { path: 'recovery',                                             loadChildren: 'app/password-recovery/password-recovery.module#PasswordRecoveryModule' },
  { path: 'admin',                                                loadChildren: 'app/admin/admin.module#AdminModule', canActivate: [AuthGuard] },
  { path: 'client',                                               loadChildren: 'app/client/client.module#ClientModule', canActivate: [AuthGuard] },
  { path: 'clientsub',                                            loadChildren: 'app/clientsub/clientsub.module#ClientSubModule', canActivate: [AuthGuard] },
  { path: 'verification',                                         loadChildren: 'app/verification/verification.module#VerificationModule'},
  { path: 'consultant',                                           loadChildren: 'app/consultant/consultant.module#ConsultantModule', canActivate: [AuthGuard] },
  { path: 'download/:compid/:type',                               loadChildren: 'app/download/download.module#DownloadModule' },
  { path: 'page-not-found',                                       loadChildren: 'app/comingsoon/coming-soon.module#ComingSoonModule' },
  { path: 'customer',                                             loadChildren: 'app/customer/customer.module#CustomerModule' },
  { path: 'signup/:compid/:conid/:name/:email/:rank/:company',    loadChildren: 'app/pps-signup/signup.module#SignupModule'},
  { path: 'share-signup/:compid/:conid/:name',                    loadChildren: 'app/share-signup/share-signup.module#ShareSignupModule'},
  { path: '',                                                     redirectTo: 'login', pathMatch: 'full' },
  { path: '**',                                                   redirectTo: 'login' }, 
];

const config: ExtraOptions = {
  useHash: true,
};

@NgModule({
  imports: [RouterModule.forRoot(routes, config)],
  exports: [RouterModule],
})
export class AppRoutingModule {
}
