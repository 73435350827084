import { Pipe, PipeTransform } from '@angular/core';
var linkify = require('linkify-it')();
import { DomSanitizer } from '@angular/platform-browser';
@Pipe({name: 'linkify'})
export class LinkifyPipe implements PipeTransform {
constructor(protected sanitizer: DomSanitizer) {}
    transform(str:string, color:string) {
        var links =  linkify.match(str);
        if(links && links.length && str){
            var link = this.linkify(str, links, color);
            return link;
        }else{
            return str;
        }
    }

    linkify(str, links, color){
        var count = 1;
        links
        for(let i = 0; i < links.length;i++){
            str = this.convert(links[i], str, color);
            if(i+1 == links.length){
                return str;
            }else{
                count++;
            }
        }
    }

    convert(l, string, color){
        var newString =  '<a href="'+l.url+'" class="'+color+ ' text-underlined" target="_blank">'+l.raw+'</a>';
        var newLink = string.replace(l.raw, newString);
        return newLink;
    }
}