import { Component, OnInit } from '@angular/core';
import { SharedGlobalService } from '../../../../@core/services/shared.global.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { UserGlobalService } from '../../../../@core/services/user.global.service';
@Component({
  selector: 'ngx-customer-add',
  templateUrl: './customer-add.component.html',
  styleUrls: ['./customer-add.component.scss']
})
export class CustomerAddComponent implements OnInit {
  data;
  loading = true;
  private socketInstance;
  customerForm:FormGroup;
  profileurl:any;
  user:any;
  isSubmit:boolean;
  constructor(
    public sgs: SharedGlobalService,
    private ngbModal: NgbModal,
    private fb: FormBuilder,
    private ugs: UserGlobalService
  ) {
   }

  ngOnInit() {
    this.buildForms();
    this.getUser();
  }

  async getUser(){
    this.user = this.ugs.$userData;
  }

  buildForms(){
      this.customerForm = this.fb.group({
        fname: ['', [Validators.required, Validators.minLength(2)]],
        lname: ['', [Validators.required, Validators.minLength(2)]],
        contact: [''],
        address: [''],
        email: ['', [Validators.required, Validators.minLength(2), Validators.pattern('[a-zA-Z0-9.-_]{1,}@[a-zA-Z.-]{2,}[.]{1}[a-zA-Z]{2,}')]],
      })
  }

  ngOnDestroy(){
    // this.socketInstance.unsubscribe();
  }

  async submit(){
    if(await this.isEmailExist()){
      this.isSubmit = true;
      this.customerForm.value.companyid = this.user.company.companyid;
      this.customerForm.value.createdby = this.user.uid;
      this.sgs.request('post', 'customer/createCustomer', this.customerForm.value, async (response) => {
        if(response.success){
          this.isSubmit = false;
          this.send(response.data);
          this.buildForms();
        }
      }, { cache:true, describe: "ERROR: Adding user" });
    }else{
      this.isSubmit = false;
      // this.sgs.Toaster('error', 'Error', 'Email already exist');
    }
    
  }

  isEmailExist(){
    return new Promise(resolve => {
      return this.sgs.request('get', 'customer/checkIfEmailExist', {email:this.customerForm.value.email}, async (response) => {
        if( response.success ){
          return resolve(true);
        }else{
          return resolve(false);
        }
      });
    })

  }

  send(customer){
    const data = {
      toEmail: customer.email,
      toName: customer.fname + " " + customer.lname,
      subject: this.user.company.companyname + ' App - New Customer Signup',
      content: '<html><img  style=" width:20%; border:4px solid #d76d9d;" src="'+this.user.company.webportal+'/images/'+this.user.company.logourl+'"><br>Welcome ' + customer.fname + ' to the ' + this.user.company.companyname + ' App! <br> <p>Please follow the 4-easy steps to confirm your account. </p> <p>Step 1: Click on the link provided: <a href="' + this.user.company.webportal + '/#/verification/customer/' + this.user.company.companyid + '/'+this.user.uid+ '/'+customer.id+'">Confirm My Account</a></p>  <p>Step 2: Create and Memorize your password to activate your account</p> <p>Step 3: Click on the download link and install the app</p> <p>Step 4. Login to your app using your email address that was displayed in "Step 2" and the password you created, and expand your network!</p><br><br>' + this.user['signature'] + '</html>'
    }
    this.sgs.request('post','communication/sendEmail', data, async response => {
      if(response.success){
        this.sgs.Toaster('success', 'Invitation Sent', 'The invitation was sent successfully.');
      }else{
        this.sgs.Toaster('warning', 'Email not Sent', 'The invitation was not sent successfully. Please try to resend in the list.');
      }
    })
  }








  // addUser(){
  //   const activeModal = this.ngbModal.open(AddUserComponent, { size: 'sm', container: 'nb-layout', windowClass: 'min_height' });
  // }

  // updateUser(id){
  //   const activeModal = this.ngbModal.open(UpdateUserComponent, { size: 'sm', container: 'nb-layout', windowClass: 'min_height' });
  //   activeModal.componentInstance.uid = id;
  // }



  

}
