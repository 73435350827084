import { Component, OnInit } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import Player from '@vimeo/player';
import { SharedGlobalService } from '../../../../../@core/services/shared.global.service';
import { UserGlobalService } from '../../../../../@core/services/user.global.service';
import { EditPostComponent } from '../edit-post/edit-post.component';
@Component({
    selector: 'ngx-post-details',
    templateUrl: './post-details.component.html',
    styleUrls: ['./post-details.component.scss']
})

export class PostDetailsComponent implements OnInit {
    conx = this.sgs.connection;
    data:any;
    typeDetails:any;
    wistiaID: any;
    type = {
        quote:false,
        image:false,
        video:false
    }
    player: Player;
    userData:any;
    constructor(
        private activeModal: NgbActiveModal,
        private ugs: UserGlobalService,
        private ngbModal: NgbModal,
        private sgs: SharedGlobalService
    ) {}

    ngOnInit() {
        this.getUserData();
        if(this.data.videoURL && this.data.videoType == 'Vimeo'){
            this.prepareVimeo(this.data.videoURL);
        } else if (this.data.videoURL && this.data.videoType == 'Wistia') {
            this.wistiaID = this.data.videoURL.substring(this.data.videoURL.lastIndexOf('/') + 1);
        }
        this.checkType();
    }

    async getUserData(){
        this.userData = this.ugs.$userData? this.ugs.$userData:await this.ugs.getUserCollectionData();
        this.checkIfHasNotif();
    }

    checkIfHasNotif(){
        this.sgs.request('get', 'notification/getNotificationWithPostId', { postId: this.data.id}, async (response) => {
            if(response.success){
                if(response.data.isRead != 1) this.updateRead(response.data);
            }
        });
    }

    updateRead(data){
        this.sgs.request('post', 'notification/updateReadNotification', data, response => { })
    }

    prepareVimeo(url){
        setTimeout(() => {
            try{
                var x = document.getElementById("handstick");
                this.player = new Player(x, {
                    url: url,
                    width: 640,
                    color:'#999'
                })
            }catch(err){
                console.log(err)
            }
        }, 1000)
    }

    checkType(){
        switch (this.data.type){
            case 'quote':
                this.typeDetails = {
                    title:'Quote',
                    icon:'nb-compose',
                    color:'#d4889e'
                }
                this.type.quote = true;
            break;
            case 'image':
                this.typeDetails = {
                    title:'Image',
                    icon:'fas fa-image',
                    color:'#73a1ff'
                }
                this.type.image = true;
            break;
            case 'video':
                this.typeDetails = {
                    title:'Video',
                    icon:'fas fa-video',
                    color:'#ff6b83'
                }
                this.type.video = true;
            break;
        }
    }

    editModal(data){
        const activeModal = this.ngbModal.open(EditPostComponent, { size: 'lg', container: 'nb-layout', windowClass: 'min_height', backdrop:'static' });
        activeModal.componentInstance.data = data;
    }

    deletePost(){
        this.sgs.Modal({
        header: 'Delete Post',
        content: "Do you want to remove "+ this.data.title+"?",
        type: 'confirmation',
        }, { size: 'sm' })
        .confirm.subscribe( response => {
            if (response) {
                this.sgs.request('post', 'post/deletePost', { id: this.data._id, status:2, companyid:this.data.companyid }, async (response) => {
                    if(response){
                        this.sgs.Toaster("error", "Message", "Post has been deleted!");
                        this.closeModal();
                    }
                });
            }
        })
    }
    
    publish(data){
        let publish = confirm("Are you sure you want to publish this post?");
        if (publish){
            data.publishStatus = !data.publishStatus;
            this.sgs.request('post', 'post/publishPost', data, async (response) => {
                if(response.success){
                        this.sgs.Toaster('success', 'Success', 'The course has been successfully publish.');
                }
            })
        }
    }

    unpublish(data){
        let unpublish = confirm("Are you sure you want to unpublish this post?");
        if (unpublish){
            data.publishStatus = !data.publishStatus;
            this.sgs.request('post', 'post/unPublishPost',  data, async (response) => {
                if(response.success){
                    this.sgs.Toaster('success', 'Success', 'The post has been successfully unpublish.');
                }
            })
        }
    }
    
    closeModal() {
        this.activeModal.close();
    }
}
