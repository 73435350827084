import { Component, OnInit, Input } from '@angular/core';
import { SharedGlobalService } from '../../../../@core/services/shared.global.service';
import { AuthService } from '../../../../@core/services/auth.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';
import { PostDetailsComponent } from '../../marketme/modals/post-details/post-details.component';
import { NotificationDetailsComponent } from '../../../../consultant/comingsoon-notification/notification-details/notification-details.component';
@Component({
    selector: 'tt-header-notification',
    styleUrls: ['./notification.component.scss'],
    templateUrl: './notification.component.html',  
})
export class NotificationComponent implements OnInit{
    @Input() notifications: any;
    @Input() messageNotifications: any;
    @Input() user: any;
    cache: any = [];


    constructor(
        private sgs: SharedGlobalService,
        private auth: AuthService,
        private ngbModal: NgbModal,
        public router: Router,
    ){
        this.loggedDefault();
        sgs.notificationEmitter.subscribe( () => {
          this.loggedDefault();
        });
     
    }

    onClickedOutside(e: Event){
        this.sgs.notificationStatus = !this.sgs.notificationStatus;
    }

    loggedDefault(){
        this.cache = this.sgs.getCachedData();
    }

    ngOnInit(){}

    read(data){
        console.log(data);
        switch(data.notifType){
            case 'notification':
                const pushActiveModal = this.ngbModal.open(NotificationDetailsComponent, { size: 'lg', container: 'nb-layout', windowClass: 'min_height' });
                data.companyid = this.user.company.companyid
                pushActiveModal.componentInstance.notification = data;
            break;
            case 'post':
                const postActiveModal = this.ngbModal.open(PostDetailsComponent, { size: 'lg', container: 'nb-layout', windowClass: 'min_height' });
                data.companyid = this.user.company.companyid;
                postActiveModal.componentInstance.notification = data;
            break;
        }
        this.router.navigate(['/'+data.forRole+data.url]);
    }
    readMessage(data){
        console.log('read');
        
        this.router.navigate(['/'+this.user.role+'/'+data.url+'/conversation/'+data.chatId]);
    }

    ngOnChanges(){
    }

    ngOnDestroy(){
        
    }

    

    
}