import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'nameCase' })
export class CapitalizePipe implements PipeTransform {

    transform(input: string): string {
        try{
            if(input && input.length){
                var split:any = input.split(/[\s-]+/);
                if(split.length > 1){
                    return ("" + input.replace(/[^\s\-\']+[\s\-\']*/g, function(word) {
                        return word.charAt(0).toUpperCase() + word.substr(1).toLowerCase();
                    }).replace(/\b(Van|De|Der|Da|Von)\b/g, function(nobiliaryParticle) {
                        return nobiliaryParticle.toLowerCase();
                    }).replace(/Mc(.)/g, function(match, letter3) {
                        return 'Mc' + letter3.toUpperCase();
                    }));
                }else{
                    return (input.charAt(0).toUpperCase() + input.slice(1).toLowerCase());
                }
            }else{
                return input;
            }
        }catch(e){
            console.log(e, input)
        }
    }
}
