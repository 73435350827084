import { Component, OnInit } from '@angular/core';

import { ToasterService } from 'angular2-toaster';
import 'style-loader!angular2-toaster/toaster.css';
import { SharedGlobalService } from './@core/services/shared.global.service';
import { UserGlobalService } from './@core/services/user.global.service';
import { AuthService } from './@core/services/auth.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ApiBridgeGlobalService } from './@core/services/api-bridge.global.service';


@Component({
  selector: 'ngx-app',
  styles: [`
    .server-offline{
      background:#ff6b83;
      padding:12px 10px;
      color:#fff;
      text-align:center;
    }
    .warning{
      background:#e67e22;
      padding:12px 10px;
      color:#fff;
      text-align:center;
    }
  `],
  template: `
    <div class="server-offline" *ngIf="!sgs.server.status && sgs.Lan().status">
      <i class="fas fa-circle-notch fa-spin" style="opacity:.75; margin-right:10px"></i>Reconnecting &nbsp;&mdash;&nbsp; Server is Offline
    </div>
    <div class="server-offline" *ngIf="!sgs.Lan().status">
      <i class="fas fa-exclamation-circle"  style="opacity:.75; margin-right:10px"></i>No Internet Connection
    </div>
    <toaster-container [toasterconfig]="sgs.ToasterInit(toast)"></toaster-container>
    <div style="position:fixed; background:rgba(0,0,0,.5); width:100%; height:100%; z-index:999999999" *ngIf="sgs.selectorLoader">
      <div style="color:#fff; position:absolute; top:50%; left:50%; text-align:center; width:200px; height:30px; z-index:99999999; margin-top:-15px; margin-left:-100px">
      <i class="fas fa-circle-notch fa-spin" style="opacity:.75; margin-right:10px"></i>Loading data..
      </div>
    </div>
    <router-outlet></router-outlet>
  `,
})
export class AppComponent implements OnInit {
  public serverSocketInstance;
  user:any;
  warn:boolean;
  constructor(
    public sgs: SharedGlobalService,
    public ugs:UserGlobalService,
    public toast: ToasterService,
    public auth:AuthService,
    public api: ApiBridgeGlobalService,
    private ngbModal: NgbModal,
  ){
    this.serverSocketInstance = this.sgs.ResponseSocket('serverStatus').subscribe();
    this.auth.userLogout.subscribe(() => {
      this.warn = false;
    })
    this.auth.userLogin.subscribe(() => {
      this.lazyLoadedData();
    })
  }

    ngOnInit() {
        if(this.auth.getTokenData()){
            this.lazyLoadedData();
        }

        // this.auth.getIpAddress();
    }

  public async lazyLoadedData(){
  /* Prepare the $userData to be used globally */
      try{
        var user = await this.ugs.getUserCollectionData();
      }catch(e){
        console.log(e);
      }
      try{
        await this.api.getPpsProfile();
      }catch(e){
        console.log(e);
      }
      try{
        // await this.api.getDownline();
      }catch(e){
        console.log(e);
      }
  }
 
  ngOnDestroy(){ 
    this.serverSocketInstance.unsubscribe(); 
  }
  // <div class="warning" *ngIf="warn">
  //      <i class="fas fa-exclamation-triangle" style=" margin-right:10px"></i>Warning &nbsp;&mdash;&nbsp; Missing either PPS ID or Password. Please provide it at the <b class="pointer"  routerLink="/consultant/profile"><u>profile page</u></b>.
  //   </div>
}
