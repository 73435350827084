import { Component, OnInit } from '@angular/core';
import { SharedGlobalService } from '../../../@core/services/shared.global.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ActivatedRoute } from '@angular/router';
import { ReportsModalComponent } from '../modals/reports/reports-modal.component';
import * as moment from 'moment';
import { ExportToCsv } from 'export-to-csv';
import * as _ from 'lodash';
@Component({
  selector: 'ngx-reports-page',
  templateUrl: './reports-page.component.html',
  styleUrls: ['./reports-page.component.scss']
})
export class ReportsPageComponent implements OnInit {
    bsValue = new Date();
    filterQuery = '';
    sortBy = 'id';
    sortOrder = 'asc';
    selectQueryString = 'Last Name';
    selectedReport = {display:'Monthly Active Users Report', value:'mau'};
    selectQuery = 'lname';

    /* Date Elements configuration */
    selectedMonth:any;
    year = moment().year();
    years = [2019, 2020, 2021, 2022, 2023, 2024, 2025, 2026, 2027, 2028, 2029, 2030];
    months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
    /* End of Date Elements */

    data;
    loading = true;
    id:any;
    message:any = '';
    private socketInstance;
  /* List of Reports that the User can generate */
    selections = [
        {display:'Monthly Active Users Report', value:'mau'},
        // {display:'First Name', value:'fname'},
        // {display:'Email', value:'email'},
    ]
    
    constructor(
        public sgs: SharedGlobalService,
        private ngbModal: NgbModal,
        public route: ActivatedRoute,
    ) {
        this.id = this.route.snapshot.params['id'];
        this.selectedMonth = moment().month();
        this.socketInstance = sgs.ResponseSocket('clients').subscribe( emitted => {
    
        });
    }

    ngOnInit() {}

    ngOnDestroy(){
        this.socketInstance.unsubscribe();
    }

    selectFilter(data){
        this.selectQuery = data.value;
        this.selectQueryString = data.display;
    }

    selectReport(data){
        this.selectedReport = data;
    }

    selectMonth(m){
        this.selectedMonth = m;
    }

    generateReport(type){
        this.data = [];
        switch(type){
            case 'mau':
                //Getting the first day of the month starting at 12:00 am
                var startDate = moment().year(this.year).month(this.selectedMonth).startOf('month').toISOString();
                //Getting the end day of the month until 11:59 pm
                var lastDate = moment().year(this.year).month(this.selectedMonth).endOf('month').toISOString();
                this.mauReport(startDate, lastDate);
            break;
            default:
                console.log('unrecognized type.');
            break;
        }
    }

    mauReport(start, end){
        this.sgs.request('get', 'user/getMonthlyActiveUsers', {companyId:parseInt(this.id), startDate:start, endDate:end}, async (response) => {
            if(response.data && response.data.length){
                // console.log(response.data)
                this.data =  response.data;
                this.message = "There are only ("+response.data.length+') active users in the month of '+this.months[this.selectedMonth]+'.';
            }else{
                this.sgs.Toaster('error', 'Failed to generate reports', 'Looks like no active user found in the month of '+this.months[this.selectedMonth]);
            }
        })
    }

    export(){
        const options = { 
            fieldSeparator: ',',
            quoteStrings: '"',
            decimalSeparator: '.',
            showLabels: true, 
            showTitle: true,
            title: 'Active Users for the Month of '+this.months[this.selectedMonth],
            useTextFile: false,
            useBom: true,
            useKeysAsHeaders: true,
            filename:this.months[this.selectedMonth].toLowerCase()+'_monthly_active_users_report'
            // headers: ['Column 1', 'Column 2', etc...] <-- Won't work with useKeysAsHeaders present!
        };
        var data = [];
        this.data.map(d => {
            var obj ={
                firstName: d.fname,
                lastName: d.lname,
                email:d.email,
                noOfLogins:d.logins.length,
                status:d.status == 1? 'active':'inactive'
            }
            data.push(obj);
        })
        data = _.orderBy(data, ['lastName'], ['asc']);
        const csvExporter = new ExportToCsv(options);
        csvExporter.generateCsv(data);
    }
}
