import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';
import { SharedGlobalService } from '../../../../@core/services/shared.global.service';
@Component({
  selector: 'ngx-reports-modal',
  templateUrl: './reports-modal.component.html',
  styleUrls: ['./reports-modal.component.scss']
})
export class ReportsModalComponent implements OnInit {
 hasSelected:boolean;
 selectedReport:string;
 currentMonth:number;
 months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
 year = moment().year();
 id;
 constructor(
    private activeModal: NgbActiveModal,
    public sgs: SharedGlobalService,
  ) { }

  ngOnInit() {
    this.currentMonth = moment().month();
  }
  closeModal() {
    this.activeModal.close();
  }

  selectMonth(m){
    this.currentMonth = m;
  }

  selectReport(report){
    this.selectedReport = report;
    this.hasSelected = true;
  }

  generateReport(type){
    switch(type){
      case 'mau':
        //Getting the first day of the month starting at 12:00 am
        var startDate = moment().year(this.year).month(this.currentMonth).startOf('month').toISOString();
        //Getting the end day of the month until 11:59 pm
        var lastDate = moment().year(this.year).month(this.currentMonth).endOf('month').toISOString();
        this.mauReport(startDate, lastDate);
      break;
      default:
      console.log('unrecognized type.');
      break;
    }
  }
  mauReport(start, end){
    this.sgs.request('get', 'user/getMonthlyActiveUsers', {companyId:parseInt(this.id), startDate:start, endDate:end}, async (response) => {
    })
  }
 
}
