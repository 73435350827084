import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { SharedGlobalService } from '../../../@core/services/shared.global.service';

@Component({
  selector: 'ngx-notification-details',
  templateUrl: './notification-details.component.html',
  styleUrls: ['./notification-details.component.scss']
})
export class NotificationDetailsComponent implements OnInit {
    conx;
    notification;
    userData;
    constructor(
        private formBuilder: FormBuilder,
        private sgs: SharedGlobalService,
        private route: ActivatedRoute
    ) {}

    updateRead(){
        this.sgs.request('post', 'notification/updateReadNotification', this.notification, response => {
            if(response.success){
            //  console.log(response.message);
            }else{
             console.log(response.message);
            }
        })
    }

    ngOnInit() {
        this.conx = this.sgs.connection;
        this.route.params.subscribe(params => {
            this.sgs.request('get', 'notification/getNotification', { id: params.id}, async (response) => {
                if(response.success) this.notification = response.data;
                if(!this.notification.isRead || (this.notification.isRead && !this.notification.isRead.length)) this.updateRead();
            })
        })
    }
}
