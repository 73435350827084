import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AdminRoutingModule,routedComponents } from './admin-routing.module';
import { ThemeModule } from '../@theme/theme.module';
import { SharedModule } from '../shared/shared.module';
import { QRCodeModule } from 'angularx-qrcode';
import { AddUserComponent } from './users/add-user/add-user.component';
import { UpdateUserComponent } from './users/update-user/update-user.component';
import { ReportsModalComponent } from './clients/modals/reports/reports-modal.component';





@NgModule({
  imports: [
    CommonModule,
    ThemeModule,
    AdminRoutingModule,
    SharedModule,
    QRCodeModule,
  ],
  entryComponents: [
    AddUserComponent,
    UpdateUserComponent,
    ReportsModalComponent
  ],
  declarations: [...routedComponents],
  providers: [],
})
export class AdminModule {
  
 }
