import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { SharedGlobalService } from '../../../../../@core/services/shared.global.service';

@Component({
  selector: 'ngx-edit-post',
  templateUrl: './edit-post.component.html',
  styleUrls: ['./edit-post.component.scss']
})
export class EditPostComponent implements OnInit {
    data:any;
    constructor(
        private activeModal: NgbActiveModal,
        private formBuilder: FormBuilder,
        private sgs: SharedGlobalService
    ) {}

    ngOnInit() {}
    
    closeModal() {
        this.activeModal.close();
    }
}
