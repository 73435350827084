import { Component, OnDestroy, AfterViewInit, Output, EventEmitter, ElementRef, Input } from '@angular/core';
import { SharedGlobalService } from '../../../@core/services/shared.global.service';

@Component({
  selector: 'ngx-tiny-mce',
  template: '',
})
export class TinyMCEComponent implements OnDestroy, AfterViewInit {

  @Output() changed = new EventEmitter<any>();
  @Input() dataToBind: string;

  editor: any;
  clearTinymceEmitter;
  hasSubscribed;

  constructor(
    private host: ElementRef,
    private sgs: SharedGlobalService
  ) { }

  ngAfterViewInit() {
    try{
        tinymce.init({
          target: this.host.nativeElement,
          menubar: false,
          plugins: ['link', 'paste'],
          skin_url: 'assets/skins/lightgray',
          setup: editor => {
            this.editor = editor;
            editor.on('keyup', () => {
              this.changed.emit(editor.getContent());
            });
            editor.on('init', () => {
              this.editor.setContent(this.dataToBind);
            });
           
              editor.on('change', () => {
                this.changed.emit(editor.getContent());
              })
            
         
          },
          height: '200',
        });
      
    }catch(err){
      console.log('No Data to bound:',err)
    }
  }

  ngOnDestroy() {
    try{
      // this.clearTinymceEmitter.unsubscribe();
      tinymce.remove(this.editor);
    }catch(e){
      console.info(e.name, e.message);
    }
  }
}
