import { Injectable, Output, EventEmitter } from '@angular/core';
import 'rxjs/add/operator/map';
import { AuthService } from './auth.service';
import { SharedGlobalService } from './shared.global.service';
import { UserGlobalService } from './user.global.service';
import { Http, Headers, RequestOptions } from '@angular/http';
import * as _ from 'lodash';
import { NbWindowService } from '@nebular/theme';
import { LoaderComponent } from './extra-components/loader/loader.component';
@Injectable()

/*
NOTE: This service is indeed for the retrieval of data from the PPS API Bridge. At this service we execute any type
of API calls to have centralize pulling of data.
*/

export class ApiBridgeGlobalService {
    testHost = "https://damsel.partyplanteam.com/";
    liveHost = "https://www.mydamselpro.net/";
    apiKey = "48FBF98A-C35F-4F05-90E6-FFDE8107E1FA";
    userData:any;
    @Output() public downlineProgress = new EventEmitter();

    /* Variables that needs to be broadcast globally */
    $groupsApiData:any;
    $ppsProfile:any;
    percentage:any = 0;
    constructor(
        private auth:AuthService,
        private sgs:SharedGlobalService,
        private ugs:UserGlobalService,
        public http: Http,
        public windowService: NbWindowService,
    ){
        this.getUser();
    }
 
    async getUser(){
        this.userData = this.ugs.$userData? this.ugs.$userData:await this.ugs.getUserCollectionData();
    }
    /* Use to get the PPS proflie data of the user */
    public getPpsProfile():any{
        var credentials = {
            "UserType": "DISTRIBUTOR",
            "Username":  this.ugs.$userData.ppsid,
            "Password":  this.ugs.$userData.ppspassword,
            "APIKey": '48FBF98A-C35F-4F05-90E6-FFDE8107E1FA',
            "HostName": this.liveHost
        }
        console.log(credentials)
        return  new Promise(async resolve => {
            if(this.ugs.$userData.ppsid && this.ugs.$userData.ppspassword){
                var request =  this.http.post("https://api.d-solmedia.com/api/Bridge/GetConsultantProfile", { UserModel: credentials }).subscribe(res => {
                var response = res.json();
                var ppsData = response.Profile;
                    if(response.ResultCode == 'SUCCESS'){
                        this.$ppsProfile = response.Profile;
                        resolve({success:true, data:ppsData});
                        request.unsubscribe();
                    }else{
                        resolve({success:false, data:response});
                        request.unsubscribe();
                    }
                })
            }else{
                return resolve({success:false, data:{Error:'Found empty username or password. Cannot proceed the request.'}});
            }
        })
    }

    public getPpsStatus( data ) : any{
        var credentials = {
            "UserType": "DISTRIBUTOR",
            "Username":  data.ppsid,
            "Password":  data.ppspassword,
            "APIKey": '48FBF98A-C35F-4F05-90E6-FFDE8107E1FA',
            "HostName": this.liveHost
        }
        return  new Promise(async resolve => {
            var request =  this.http.post("https://api.d-solmedia.com/api/Bridge/GetConsultantProfile", { UserModel: credentials }).subscribe(res => {
            var response = res.json();
            // console.log('Get PPS Status response: ',response)
                if(response.ResultCode == 'SUCCESS'){
                    resolve( { success:true, isActive: response.Profile.IsActive } );
                    request.unsubscribe();
                }else{
                    resolve( { success:false, data:response } );
                    request.unsubscribe();
                }
            })
        })
    }

    public validatePpsCredentials(username, password):any{
        var credentials = {
            "UserType": "DISTRIBUTOR",
            "Username":  username,
            "Password":  password,
            "APIKey": '48FBF98A-C35F-4F05-90E6-FFDE8107E1FA',
            "HostName": this.liveHost
        }

        return new Promise(callback => {
            var request =  this.http.post("https://api.d-solmedia.com/api/Bridge/GetConsultantProfile", { UserModel: credentials }).subscribe(res => {
                var response = res.json();
                var ppsData = response.Profile;
                if(response.ResultCode == 'SUCCESS'){
                    callback({success:true, data:ppsData});
                    request.unsubscribe();
                }else{
                    callback({success:false, data:response});
                    request.unsubscribe();
                }
            })
        })
    }

    /* Use to get the downline consultants of the user */
    public getDownline():any{
        var credentials = {
            "UserType": "DISTRIBUTOR",
            "Username": this.ugs.$userData.ppsid,
            "Password": this.ugs.$userData.ppspassword,
            "APIKey": '',
            "HostName": this.liveHost
        };

        var userData = {
            ppsid:this.ugs.$userData.ppsid,
            uid:this.ugs.$userData.uid
        }

        return new Promise(promise => {
            if(this.ugs.$userData.ppsid && this.ugs.$userData.ppspassword){
                this.sgs.request('get', 'pps/getDownline', { UserModel: credentials, userData:userData }, async (response) => {
                    if(response.success){
                        this.$groupsApiData = response.data;
                        return promise({success:true, extracted:response.data});
                    }else{
                        this.sgs.Toaster('error', 'Request Failed', 'Downline request was unsuccessful.')
                    }
                }, {toaster:false})
            }else{
                return promise({success:false, data:{Error:'Found empty username or password. Cannot proceed the request.'}});
            }
        })
    }

    public getUpline():any{
        var credentials = {
            "UserType": "DISTRIBUTOR",
            "Username": this.ugs.$userData.ppsid,
            "Password": this.ugs.$userData.ppspassword,
            "APIKey": '',
            "HostName": this.liveHost
        };

        var userData = {
            ppsid:this.ugs.$userData.ppsid,
            uid:this.ugs.$userData.uid
        }

        return new Promise(promise => {
            if(this.ugs.$userData.ppsid && this.ugs.$userData.ppspassword){
                this.sgs.request('get', 'pps/getUpline', { UserModel: credentials, userData:userData }, async (response) => {
                    if(response.success){
                        if(response.data.UpLine.length){
                            return promise({success:true, data:response.data.UpLine[0]});
                        }else{
                            return promise({success:false, message:'No Upline Data Found'});
                        }
                    }else{
                        return promise({success:false, message: response.message});
                    }
                }, {toaster:false})
            }else{
                return promise({success:false, data:{Error:'Found empty username or password. Cannot proceed the request.'}});
            }
        })
    }

    public getAutoLogin():any{
        var credentials = {
            "UserType": "DISTRIBUTOR",
            "Username": this.ugs.$userData['ppsid'],
            "Password": this.ugs.$userData['ppspassword'],
            "APIKey": '',
            "HostName": this.liveHost
        };

        var userData = {
            ppsid:this.ugs.$userData.ppsid,
            uid:this.ugs.$userData.uid
        }

        return new Promise(promise => {
            if(this.ugs.$userData.ppsid && this.ugs.$userData.ppspassword){
                this.sgs.request('get', 'pps/getAutoLogin', { UserModel: credentials, userData:userData }, async (response) => {
                    if(response.success){
                    return promise({success:true, data:response.data.RedirectUrl});
                    }else{
                    return promise({success:false, message: response.message});
                    }
                }, {toaster:false})
            }else{
                return promise({success:false, data:{Error:'Found empty username or password. Cannot proceed the request.'}});
            }
        })
    }
    public getPublicEvents():any{
        return new Promise(async promise => {
            var user = this.userData = this.ugs.$userData? this.ugs.$userData:await this.ugs.getUserCollectionData();
            var credentials = {
                "UserType": "PUBLIC",
                "Username": user['ppsid'],
                "Password": user['ppspassword'],
                "APIKey": '',
                "HostName": this.liveHost
            };

            var userData = {
                ppsid:this.ugs.$userData.ppsid,
                uid:this.ugs.$userData.uid
            }
            if(this.ugs.$userData.ppsid && this.ugs.$userData.ppspassword){
                this.sgs.request('get', 'pps/getPublicEvents', { UserModel: credentials, userData:userData }, async (response) => {
                    if(response.success){
                        return promise({success:true, data:response.data});
                    }else{
                        return promise({success:false, message: response.message});
                    }
                }, {toaster:false})
            }else{
                return promise({success:false, data:{Error:'Found empty username or password. Cannot proceed the request.'}});
            }
        })
    }
    /* Execute the call on PPS bridge and has 2 parameters the url and the credentials which use as a key to call. */
    call(url, credentials){
        return this.http.post(url, { UserModel: credentials });
    }

    extractDownline(downline){
        const callData = downline[0];
        var arrayCandidate = [];
        var groups = [];
        var all = [];
        var rankGroups = [];
        var progressLoad = 0;
        return new Promise(async resolve => {
            for(let i= 0; i < callData.Children.length; i++ ){
                var _child = callData.Children[i];
                if(_child.Children.length){

                    arrayCandidate.push(_child);
                        var index = 0;
                    while(arrayCandidate.length != 0){
                        var count = 1;
                        if( arrayCandidate[index].Children.length){
                            const candidate = arrayCandidate[index];
                            const parentPromise = await this.checkFromAppDbPromise(candidate);
                            if(parentPromise){
                                if(parentPromise['id']){
                                    if( !_.find(all, {'Number':parentPromise['Number']}) && parentPromise['Number'] != this.ugs.$userData.ppsid && parentPromise['id'] != this.ugs.$userData.uid){
                                        all.push(parentPromise);
                                    }else if(!all.length){
                                        all.push(parentPromise);
                                    }
                                }
                                if(parentPromise['Children'] && parentPromise['Children'].length){
                                    var group = {
                                            groupName:parentPromise['Name']+' Group',
                                            id:parentPromise['Number'],
                                            upLineId:parentPromise['sponsor'],
                                            Rank:parentPromise['Rank'],
                                            downLine:[],
                                            uid:parentPromise['id']
                                    };
                                    parentPromise['Children'].map(async child => {
                                        group.downLine.push(child);
                                        if(!_.find(all, {'Number':child.Number}) && arrayCandidate[i]['Number'] != this.ugs.$userData.ppsid ){
                                            all.push(child)
                                        }else if(!all.length){
                                        all.push(child);
                                        }
                                        if(child.Children && child.Children.length){
                                            arrayCandidate.push(child);
                                        }else{
                                        progressLoad = (i+1)/callData.Children.length;
                                        this.downlineProgress.emit(progressLoad);
                                            if(arrayCandidate.length == 1 && count == arrayCandidate[i].Children.length && i+1 == callData.Children.length){
                                            return resolve(await this.createPpsGroupEmit(all, rankGroups, groups));
                                            }
                                        }
                                        count++;
                                    })

                                    if(group.id == this.ugs.$userData.ppsid){
                                        group.groupName = 'My Group';
                                    }
                                    if(group.downLine.length){
                                        groups.push(group);
                                    }
                                }
                            }else{
                                progressLoad = (i+1)/callData.Children.length;
                                this.downlineProgress.emit(progressLoad);
                                if(arrayCandidate.length == 1 && i+1 == callData.Children.length){
                                return resolve(await this.createPpsGroupEmit(all, rankGroups, groups));
                                }
                            }
                            }
                        /* Extract all Children */
                        arrayCandidate.splice(index,1);
                    }
                }else{
                    progressLoad = (i+1)/callData.Children.length;
                    this.downlineProgress.emit(progressLoad);
                    var downline = await this.checkFromAppDbPromise(_child);
                    if(downline){
                        all.push(downline);
                    }
                    if((arrayCandidate.length == 0 || arrayCandidate.length == 1) && i+1 == callData.Children.length){
                        return resolve(await this.createPpsGroupEmit(all, rankGroups, groups));
                    }
                }
            }
        })
    }

  createPpsGroupEmit(all, rankGroups, groups){
    return new Promise(resolve => {
      var rankGroup = _.groupBy(all, 'Rank');
      var types = _.keysIn(rankGroup);
      var uplines =_.keysIn(_.groupBy(all, 'sponsor'));
      types.map(k => {
          rankGroups.push({groupName: k.toUpperCase(), downLine:rankGroup[k], sponsorIds:uplines});
      });
      var ppsGroups = {
        downlines:groups? groups: 'No data found',
        rankGroups:rankGroups? rankGroups: 'No data found',
        all:all? all: 'No data found'
      }
      return resolve(ppsGroups);
    })
  }

  public checkFromAppDbPromise(consultant){
    return new Promise(resolve => {
        return this.sgs.request('get', 'consultant/getConsultantByPpsid',{id:consultant.Number}, (response) => {
            if(response.data && response.data.length){
                var cData = response.data[0];
                var data = {
                    Name:cData.fname+' '+cData.lname,
                    id:cData.uid,
                    Rank:consultant.Rank,
                    avatar:cData.profileurl,
                    role:cData.role,
                    Number:consultant.Number,
                    sponsor:consultant.SponsorID,
                    onesignal:cData.onesignal && cData.onesignal.length? cData.onesignal:undefined
                };

                if(consultant.Children && consultant.Children.length){
                    var children = [];
                    var count = 1;
                    consultant.Children.forEach(child => {
                        return this.sgs.request('get', 'consultant/getConsultantByPpsid',{id:child.Number}, (res) => {
                            if(res && res.data.length){
                                var objData = res.data[0];
                                var obj = {
                                    Name:objData.fname+' '+objData.lname,
                                    id:objData.uid,
                                    Rank:child.Rank,
                                    avatar:objData.profileurl,
                                    role:objData.role,
                                    Number:child.Number,
                                    sponsor:consultant.SponsorID,
                                    onesignal:objData.onesignal && objData.onesignal.length? objData.onesignal:undefined,
                                };
                                if(child.Children){
                                  obj['Children'] = child.Children;
                                }
                                children.push(obj);
                                if(count == consultant.Children.length){
                                    data['Children'] = children;
                                    return resolve(data);
                                }else{
                                    count++;
                                }
                            }else{
                                if(count == consultant.Children.length){
                                    data['Children'] = children;
                                    return resolve(data);
                                }else{
                                    count++;
                                }
                            }
                        })
                    })
                }else{
                    return resolve(data);
                }
            }else{
                var parent = {
                    Name:consultant.Name,
                    Rank:consultant.Rank,
                    Number:consultant.Number
                };
                if(consultant.Children && consultant.Children.length){
                    var children = [];
                    var count = 1;
                    consultant.Children.forEach(child => {
                        return this.sgs.request('get', 'consultant/getConsultantByPpsid',{id:child.Number}, (res) => {
                            if(res && res.data.length){
                                var objData = res.data[0];
                                var obj = {
                                    Name:objData.fname+' '+objData.lname,
                                    id:objData.uid,
                                    Rank:child.Rank,
                                    avatar:objData.profileurl,
                                    role:objData.role,
                                    Number:child.Number,
                                    sponsor:consultant.SponsorID,
                                    onesignal:objData.onesignal && objData.onesignal.length? objData.onesignal:undefined
                                };
                                if(child.Children){
                                  obj['Children'] = child.Children;
                                }
                                children.push(obj);
                                if(count == consultant.Children.length){
                                    parent['Children'] = children;
                                    return resolve(parent);
                                }else{
                                    count++;
                                }
                            }else{
                                if(count == consultant.Children.length){
                                    parent['Children'] = children;
                                    return resolve(parent);
                                }else{
                                    count++;
                                }
                            }
                        })
                    })
                }else{
                    return resolve(undefined);
                }
            }
        })
    })
}

toastLoader(percent){
  this.percentage = percent;
}
}


