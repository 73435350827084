import { RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';

import { AdminComponent } from './admin.component';
import { DashboardComponent} from './dashboard/dashboard.component';
import { UsersListComponent } from './users/users-list/users-list.component';
import { ClientsListComponent } from './clients/clients-list/clients-list.component';
import { ClientAddComponent } from './clients/client-add/client-add.component';
import { ClientEditComponent } from './clients/client-edit/client-edit.component';
import { ClientDetailsComponent } from './clients/client-details/client-details.component';
import { AddUserComponent } from './users/add-user/add-user.component';
import { UpdateUserComponent } from './users/update-user/update-user.component';
import { ProfileComponent } from './profile/profile.component';
import { ClientUsersComponent } from './clients/client-users/client-users.component';
import { ReportsModalComponent } from './clients/modals/reports/reports-modal.component';
import { ReportsPageComponent } from './clients/reports-page/reports-page.component';
import { ConsultantAddComponent } from './clients/consultants/consultant-add/consultant-add.component';
import { ConsultantEditComponent } from './clients/consultants/consultant-edit/consultant-edit.component';
import { ConsultantDetailsComponent } from './clients/consultants/consultant-details/consultant-details.component';
import { StatusCardComponent } from './dashboard/status-card/status-card.component';
import { CustomerListComponent } from './clients/customers/customer-list/customer-list.component';
import { CustomerAddComponent } from './clients/customers/customer-add/customer-add.component';
import { CustomerEditComponent } from './clients/customers/customer-edit/customer-edit.component';
import { CustomerDetailsComponent } from './clients/customers/customer-details/customer-details.component';

const routes: Routes = [{
  path: '',
  component: AdminComponent,
  children: [
    {
      path: 'dashboard',
      component: DashboardComponent,
      children: [
        {
          path: 'dashboard',
          component: DashboardComponent,
        },
        {
          path: 'dashboard',
          component: DashboardComponent,
        }
      ]
    },
    {
      path: 'users',
      children: [
        {
          path: 'all-users',
          component: UsersListComponent,
        },
      ]
    },
    {
      path: 'clients',
      children: [
        {
          path: 'all-clients',
          component:ClientsListComponent,
        },
        {
          path: 'add-client',
          component: ClientAddComponent,
        },
        {
          path: 'edit-client/:id',
          component: ClientEditComponent,
        },
        {
          path: 'client-details/:id',
          component: ClientDetailsComponent,
        },
        {
          path: 'client-details/:id',
          component: ClientDetailsComponent,
        },
        {
          path: 'client-users/:id/:name',
          component: ClientUsersComponent,
        },
      ]
    },
    {
      path: 'consultants',
      children: [
        {
          path: 'add-consultant/:id',
          component: ConsultantAddComponent,
        },
        {
          path: 'edit-consultant/:id',
          component: ConsultantEditComponent,
        },
        {
          path: 'consultant-details/:id',
          component: ConsultantDetailsComponent,
        },
      ]
    },
    {
      path: 'customers',
      children: [
        {
          path: 'all-customers',
          component:CustomerListComponent,
        },
        {
          path: 'add-customer',
          component: CustomerAddComponent,
        },
        {
          path: 'edit-customer/:id',
          component: CustomerEditComponent,
        },
        {
          path: 'customer-details/:id',
          component: CustomerDetailsComponent,
        },
      ]
    },
    {    
      path: 'reports/:id',
      component: ReportsPageComponent,
    },
    {
      path: 'profile',
      component: ProfileComponent,
    },


    {
      path: '',
      redirectTo: 'dashboard',
      pathMatch: 'full',
    }
  ],
}];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class AdminRoutingModule {
}
export const routedComponents = [
  AdminComponent,
  DashboardComponent,
  UsersListComponent,
  AddUserComponent,
  UpdateUserComponent,
  ClientsListComponent,
  ClientAddComponent,
  ClientEditComponent,
  ClientDetailsComponent,
  ProfileComponent,
  ClientUsersComponent,
  ReportsModalComponent,
  ReportsPageComponent,
  ConsultantAddComponent,
  ConsultantEditComponent,
  ConsultantDetailsComponent,
  CustomerListComponent,
  CustomerAddComponent,
  CustomerEditComponent,
  CustomerDetailsComponent,
  /* Dashboard Component */
  StatusCardComponent
];
